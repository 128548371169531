import Swiper from 'swiper/bundle';
import { A11y, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css/bundle';

document.addEventListener('DOMContentLoaded', function() {
    new Swiper(".case-studies-swiper", {
        modules: [A11y, Pagination, Navigation],
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
            bulletElement: 'button',
            renderBullet: function (index, className) {
                return '<button class="' + className + '" aria-label="Go to slide ' + (index + 1) + '"></button>';
            },
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            disabledClass: 'opacity-50 cursor-not-allowed',
        },
        a11y: {
            prevSlideMessage: 'Previous slide',
            nextSlideMessage: 'Next slide',
            firstSlideMessage: 'This is the first slide',
            lastSlideMessage: 'This is the last slide',
            paginationBulletMessage: 'Go to slide {{index}}',
            notificationClass: 'swiper-notification',
            containerMessage: 'Case study slider',
            containerRoleDescriptionMessage: 'carousel',
            itemRoleDescriptionMessage: 'slide',
        },
    });
});
