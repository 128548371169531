document.addEventListener('DOMContentLoaded', function() {
    const mobileMenuButton = document.getElementById('mobile-menu-button');
    const mainNav = document.getElementById('main-nav');
    const menuItems = mainNav.querySelectorAll('li > a');
    
    // Toggle mobile menu 
    mobileMenuButton.addEventListener('click', function() {
      mainNav.classList.toggle('hidden');
    });
  
    // Handle submenu toggling and accessibility
    menuItems.forEach(item => {
      const submenu = item.nextElementSibling;
      if (submenu) {
        item.addEventListener('click', function(e) {
          e.preventDefault();
          const expanded = this.getAttribute('aria-expanded') === 'true' || false;
          this.setAttribute('aria-expanded', !expanded);
          submenu.classList.toggle('hidden');
        });
  
        // Close submenu when clicking outside
        document.addEventListener('click', function(e) {
          if (!item.contains(e.target) && !submenu.contains(e.target)) {
            item.setAttribute('aria-expanded', 'false');
            submenu.classList.add('hidden');
          }
        });
  
        // Keyboard navigation
        item.addEventListener('keydown', function(e) {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            this.click();
          }
        });
  
        submenu.querySelectorAll('a').forEach(subItem => {
          subItem.setAttribute('role', 'menuitem');
          subItem.addEventListener('keydown', function(e) {
            if (e.key === 'Escape') {
              e.preventDefault();
              item.focus();
              item.click();
            }
          });
        });
      }
    });
  
    // Handle window resize
    window.addEventListener('resize', function() {
      if (window.innerWidth >= 768) { // md breakpoint
        mainNav.classList.remove('hidden');
      } else {
        mainNav.classList.add('hidden');
      }
    });
  });